<template>
  <div data-app>
    <b-alert show variant="light" class="alert alert-elevate">
      <div class="alert-icon">
        <i class="flaticon-warning kt-font-brand"></i>
      </div>
      <div class="alert-text">{{ $t("CLASS_MATERIALS_UPLOADED.GUIDE") }}</div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet :title="title">
          <template v-slot:body>
            <div v-show="!fileView.state">
              <b-overlay :show="fileView.loading" rounded="sm">
                <b-alert
                  :variant="response.type"
                  v-model="response.status"
                  dismissible
                  >{{ response.message }}</b-alert
                >
                <b-row>
                  <b-col>
                    <b-form-select
                      v-model="faculty_department_selected"
                      :options="faculty_departments"
                      @change="getUploadedFilesList"
                    ></b-form-select>
                  </b-col>
                  <b-col>
                    <b-form-select
                      v-model="semester_selected"
                      :options="semesters"
                      @change="getUploadedFilesList"
                    ></b-form-select>
                  </b-col>
                  <b-col>
                    <b-form-select
                      v-model="student_class_selected"
                      :options="student_classes"
                      @change="getUploadedFilesList"
                    ></b-form-select>
                  </b-col>
                  <b-col>
                    <b-form-select
                      v-model="curriculum_subject_selected"
                      :options="curriculum_subjects"
                      @change="getUploadedFilesList"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row>
                  <b-table
                    striped
                    hover
                    show-empty
                    :items="items"
                    :fields="fields"
                    :busy="isBusy"
                    class="mt-3"
                  >
                    <template v-slot:empty="scope">
                      <p class="text-center">
                        {{
                          $t("CLASS_MATERIALS_UPLOADED.NO_MATERIALS_UPLOADED")
                        }}
                      </p>
                    </template>
                    <template v-slot:cell(actions)="{ item }">
                      <b-button
                        pill
                        size="sm"
                        variant="info"
                        v-b-tooltip.hover
                        :title="$t('GENERAL.VIEW_FILE')"
                        @click="loadFile(item.type, item.id)"
                      >
                        <i
                          class="fa fa-eye"
                          style="font-size: 1.3em; margin-left: 0;"
                        ></i>
                      </b-button>
                      &nbsp;&nbsp;
                      <b-button
                        pill
                        size="sm"
                        variant="success"
                        v-b-tooltip.hover
                        :title="$t('GENERAL.DOWNLOAD')"
                        v-if="item.type !== 'Youtube'"
                        @click="downloadFile(item.type, item.id)"
                      >
                        <i
                          class="fa fa-download"
                          style="font-size: 1.3em; margin-left: 0;"
                        ></i>
                      </b-button>
                      &nbsp;
                      <b-button
                        v-if="item.is_deletable"
                        pill
                        size="sm"
                        variant="danger"
                        v-b-tooltip.hover
                        :title="$t('GENERAL.DELETE_FILE')"
                        @click="deleteFile(item.id, false)"
                      >
                        <i
                          class="fa fa-trash"
                          style="font-size: 1.3em; margin-left: 0;"
                        ></i>
                      </b-button>
                    </template>
                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
                      </div>
                    </template>
                  </b-table>
                </b-row>
                <b-row>
                  <b-col>
                    <b-pagination
                      v-model="current_page"
                      :total-rows="total_rows"
                      :per-page="items_per_page"
                      align="center"
                      @change="getUploadedFilesList"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-overlay>
            </div>
            <b-row v-if="fileView.state">
              <b-col>
                <b-button
                  pill
                  size="sm"
                  variant="info"
                  v-b-tooltip.hover
                  :title="$t('GENERAL.BACK')"
                  @click="unLoadFile"
                  class="kt-margin-b-10"
                >
                  <i
                    class="fa fa-angle-left"
                    style="font-size: 1.3em; margin-left: 0;"
                  ></i>
                  {{ $t("GENERAL.BACK") }}
                </b-button>
                <FilePreview
                  :type="fileView.type"
                  :path="fileView.url"
                  :model="fileView.model"
                  :id="fileView.id"
                  discussion="true"
                />
              </b-col>
            </b-row>
          </template>
        </KTPortlet>
      </div>
    </div>
    <v-dialog v-model="dialog.status" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text>{{ dialog.message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="deleteFile(dialog.id, true)"
          >
            {{ $t("GENERAL.CONFIRM") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="dialog.status = false"
            v-if="dialog.type === 'delete'"
          >
            {{ $t("GENERAL.CANCEL") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import FilePreview from "@/views/pages/FilePreview";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ApiService from "@/common/api.service";

export default {
  data() {
    return {
      title: this.$t("MENU.CLASS_MATERIALS_UPLOADED"),
      isBusy: true,
      dialog: {
        type: null,
        status: false,
        title: null,
        message: null,
        id: null
      },
      response: {
        status: false,
        type: null,
        message: null
      },
      fileView: {
        loading: false,
        state: false,
        type: null,
        model: "student-class-shared-file",
        url: null,
        id: null
      },
      fields: [
        { faculty: this.$t("GENERAL.FACULTY") },
        { student_class: this.$t("GENERAL.CLASS") },
        { subject: this.$t("GENERAL.SUBJECT") },
        { file_name: this.$t("GENERAL.FILE_NAME") },
        { upload_time: this.$t("CLASS_MATERIALS.UPLOAD_TIME") },
        { type: this.$t("GENERAL.FILE_TYPE") },
        { size: this.$t("GENERAL.FILE_SIZE") },
        { actions: this.$t("GENERAL.ACTIONS") }
      ],
      items: [],
      faculty_departments: [],
      faculty_department_selected: 0,
      semesters: [],
      semester_selected: 0,
      student_classes: [],
      student_class_selected: 0,
      curriculum_subjects: [],
      curriculum_subject_selected: 0,
      current_page: 1,
      total_rows: 1,
      items_per_page: 1
    };
  },
  components: {
    KTPortlet,
    FilePreview
  },
  methods: {
    getUploadedFilesList() {
      this.isBusy = true;
      let filters = {
        faculty_department_id: this.faculty_department_selected,
        semester: this.semester_selected,
        student_class_id: this.student_class_selected,
        curriculum_subject_id: this.curriculum_subject_selected
      };
      ApiService.post(
        "lecturer/uploaded-materials-list?page=" + this.current_page,
        filters
      ).then(({ data }) => {
        this.items = data.items;
        this.items_per_page = data.items_per_page;
        this.total_rows = data.total_rows;
        this.isBusy = false;
      });
    },
    loadFile(type, id) {
      this.fileView.loading = true;
      ApiService.get("files/get-url/load/student-class-shared-file/" + id)
        .then(({ data }) => {
          this.fileView.state = true;
          this.fileView.type = type;
          this.fileView.url = data;
          this.fileView.id = id;
          this.fileView.loading = false;
        })
        .catch(() => {
          this.fileView.loading = false;
        });
    },
    unLoadFile() {
      this.fileView.state = false;
      this.fileView.type = null;
      this.fileView.url = null;
      this.fileView.id = null;
    },
    downloadFile(type, id) {
      this.fileView.loading = true;
      ApiService.get("files/get-url/download/student-class-shared-file/" + id)
        .then(({ data }) => {
          this.fileView.loading = false;
          window.open(data, "_blank");
        })
        .catch(() => {
          this.fileView.loading = false;
        });
    },
    deleteFile(id, confirm) {
      if (confirm === false) {
        this.dialog.status = true;
        this.dialog.type = "delete";
        this.dialog.title = this.$t("GENERAL.DELETE_CONFIRM");
        this.dialog.message = this.$t("GENERAL.DELETE_INFORMATION");
        this.dialog.id = id;
      } else if (confirm === true) {
        this.dialog.status = false;
        this.dialog.type = null;
        this.fileView.loading = true;
        this.dialog.id = null;
        ApiService.delete("files/delete/student-class-shared-file/" + id)
          .then(() => {
            this.fileView.loading = false;
            this.response.status = true;
            this.response.type = "success";
            this.response.message = this.$t("GENERAL.DELETED");
            this.items = this.items.filter(item => item.id !== id);
          })
          .catch(() => {
            this.fileView.loading = false;
            this.response.status = true;
            this.response.type = "danger";
            this.response.message = this.$t("GENERAL.DELETE_FAILURE");
          });
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.CLASS_MATERIALS_UPLOADED"),
        route: this.$route
      },
      { title: "" }
    ]);
    ApiService.get("lecturer/assigned-classes-filters").then(({ data }) => {
      this.title +=
        " " +
        this.$t("GENERAL.FOR_YEAR_TERM", {
          year: data.year,
          term: data.term
        });
      this.faculty_departments = data.faculty_departments;
      this.faculty_departments.unshift({
        value: 0,
        text: this.$t("GENERAL.ALL_FACULTIES")
      });
      this.semesters = data.semesters;
      this.semesters.unshift({
        value: 0,
        text: this.$t("GENERAL.ALL_SEMESTERS")
      });
      this.student_classes = data.student_classes;
      this.student_classes.unshift({
        value: 0,
        text: this.$t("GENERAL.ALL_CLASSES")
      });
      this.curriculum_subjects = data.curriculum_subjects;
      this.curriculum_subjects.unshift({
        value: 0,
        text: this.$t("GENERAL.ALL_SUBJECTS")
      });
      this.getUploadedFilesList();
    });
  },
  watch: {
    faculty_department_selected: function() {
      this.current_page = 1;
    },
    semester_selected: function() {
      this.current_page = 1;
    },
    student_class_selected: function() {
      this.current_page = 1;
    },
    curriculum_subject_selected: function() {
      this.current_page = 1;
    }
  }
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal !important;
}
</style>
